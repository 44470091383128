import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { FlagValues } from 'flags/react'
import { useEffect } from 'react'
import { Config } from '#app/config'
import { loadGTM } from './tools/gtm'
import { loadLinkedin } from './tools/linkedin'
import { loadOpenPanel } from './tools/openpanel'
import { loadSyft } from './tools/syft'

export function ThirdPartyTools() {
  useEffect(() => {
    if (Config.ENABLE_LINKEDIN_ANALYTICS) loadLinkedin()
    if (Config.GOOGLE_TAG_MANAGER_CONTAINER_ID)
      loadGTM(Config.GOOGLE_TAG_MANAGER_CONTAINER_ID)
    if (Config.SYFT_SOURCE_ID) loadSyft(Config.SYFT_SOURCE_ID)
    if (Config.OPENPANEL_CLIENT_ID) loadOpenPanel(Config.OPENPANEL_CLIENT_ID)
  }, [])

  return (
    <>
      {Config.ENABLE_VERCEL_SPEED_INSIGHTS && <SpeedInsights />}
      {Config.ENABLE_VERCEL_ANALYTICS && <VercelAnalytics />}
      {Config.ENABLE_LINKEDIN_ANALYTICS && (
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=5845881&fmt=gif"
          />
        </noscript>
      )}

      {/* Provide the Vercel toolbar with the flag config from Hypertune */}
      {window.featureFlags?.encryptedValues && (
        <FlagValues values={window.featureFlags.encryptedValues} />
      )}
    </>
  )
}
