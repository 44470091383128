/**
 * @generated SignedSource<<b999fe34d1059eda590229365e49f85d>>
 * @relayHash 1c3b37dc685f8504d7e03b1f6388f391
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 975688302f8fcdb3b50facdda91f8677d3e36939ec1b2a808be1e335ac1edaa6

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type checksPageQuery$variables = {
  account: string;
  after?: string | null | undefined;
  branch: string;
  first?: number | null | undefined;
  graph: string;
};
export type checksPageQuery$data = {
  readonly graphByAccountSlug: {
    readonly " $fragmentSpreads": FragmentRefs<"checksPageFragment">;
  };
};
export type checksPageQuery = {
  response: checksPageQuery$data;
  variables: checksPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v5 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "branch",
    "variableName": "branch"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "checksPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "Graph",
          "kind": "LinkedField",
          "name": "graphByAccountSlug",
          "plural": false,
          "selections": [
            {
              "args": (v6/*: any*/),
              "kind": "FragmentSpread",
              "name": "checksPageFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "checksPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "SchemaCheckConnection",
            "kind": "LinkedField",
            "name": "schemaChecks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SchemaCheckEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchemaCheck",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "errorCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subgraphName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchemaCheckGitCommit",
                        "kind": "LinkedField",
                        "name": "gitCommit",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "branch",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "branch"
            ],
            "handle": "connection",
            "key": "checksPageFragment_schemaChecks",
            "kind": "LinkedHandle",
            "name": "schemaChecks"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c3b37dc685f8504d7e03b1f6388f391",
    "id": "975688302f8fcdb3b50facdda91f8677d3e36939ec1b2a808be1e335ac1edaa6",
    "metadata": {},
    "name": "checksPageQuery",
    "operationKind": "query",
    "text": "query checksPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n  $first: Int\n  $after: String\n) {\n  graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    ...checksPageFragment_y8WDT\n    id\n  }\n}\n\nfragment checksPageFragment_y8WDT on Graph {\n  schemaChecks(branch: $branch, first: $first, after: $after) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        ...checksRowFragment\n        __typename\n      }\n      cursor\n    }\n  }\n  id\n}\n\nfragment checksRowFragment on SchemaCheck {\n  id\n  createdAt\n  errorCount\n  subgraphName\n  gitCommit {\n    branch\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7b84b533e8bea206ca801daf6e126ff";

export default node;
