import type { ComponentProps } from "react"

export type UiIconName =
  | 'alert'
  | 'analytics'
  | 'arrow-down'
  | 'arrow-right'
  | 'arrow-up'
  | 'avatar'
  | 'bars'
  | 'bell'
  | 'billing'
  | 'book'
  | 'branch'
  | 'calendar'
  | 'changelog'
  | 'chat'
  | 'check'
  | 'checks'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'circle-stroke'
  | 'clock'
  | 'close'
  | 'code-circle'
  | 'collapse'
  | 'comment'
  | 'copy'
  | 'danger-zone'
  | 'database'
  | 'deployments'
  | 'discord'
  | 'discussion'
  | 'documentation'
  | 'domain'
  | 'dot'
  | 'edit2'
  | 'ellipsis-vertical'
  | 'enter-key'
  | 'env-vars'
  | 'expand'
  | 'external-link'
  | 'eye'
  | 'fail'
  | 'federated'
  | 'file-upload'
  | 'filter'
  | 'folder'
  | 'four-diamonds'
  | 'gear'
  | 'git-commit'
  | 'git'
  | 'github'
  | 'graphql'
  | 'headset'
  | 'history'
  | 'home'
  | 'information'
  | 'integrations'
  | 'invoices'
  | 'key'
  | 'link'
  | 'lock'
  | 'logs'
  | 'magnifying-glass'
  | 'mail'
  | 'mark-as-read'
  | 'minus-smooth'
  | 'minus'
  | 'organization'
  | 'pathfinder'
  | 'pathfinder2'
  | 'pause'
  | 'pencil'
  | 'pin-remove'
  | 'pin'
  | 'play'
  | 'plus-circle'
  | 'plus'
  | 'proposals'
  | 'refresh'
  | 'schema'
  | 'settings'
  | 'shield-lock'
  | 'sort'
  | 'split-diff'
  | 'standalone'
  | 'star'
  | 'subgraphs'
  | 'success'
  | 'terminal'
  | 'traces'
  | 'trash'
  | 'unified-diff'
  | 'user'
  | 'users'
  | 'window'

export function UiIconSvg({ assetName, ...props }: { assetName: string } & ComponentProps<'svg'>) {
  return (
    <svg {...props}>
      <use href={`/sprites/ui-icon-sprite.cf85e20d.svg#asset-${assetName}`} />
    </svg>
  )
}
