/**
 * @generated SignedSource<<8d6cf65ae0479579250d310c9ca51326>>
 * @relayHash 65aa7097f51a980c2954ee8a280e1513
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a856fa4d4d62a4462307053b1a05daa6c6fe8ec81ef29385d422595970be8a06

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type customChecksPageQuery$variables = {
  account: string;
  graph: string;
};
export type customChecksPageQuery$data = {
  readonly graphByAccountSlug: {
    readonly customCheckWebhooks: ReadonlyArray<{
      readonly __typename: "CustomCheckWebhook";
    }> | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"customChecksList">;
  };
};
export type customChecksPageQuery = {
  response: customChecksPageQuery$data;
  variables: customChecksPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "customChecksPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Graph",
          "kind": "LinkedField",
          "name": "graphByAccountSlug",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "customChecksList"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomCheckWebhook",
              "kind": "LinkedField",
              "name": "customCheckWebhooks",
              "plural": true,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "customChecksPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomCheckWebhook",
            "kind": "LinkedField",
            "name": "customCheckWebhooks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HttpHeader",
                "kind": "LinkedField",
                "name": "headers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65aa7097f51a980c2954ee8a280e1513",
    "id": "a856fa4d4d62a4462307053b1a05daa6c6fe8ec81ef29385d422595970be8a06",
    "metadata": {},
    "name": "customChecksPageQuery",
    "operationKind": "query",
    "text": "query customChecksPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    id\n    ...customChecksList\n    customCheckWebhooks {\n      __typename\n      id\n    }\n  }\n}\n\nfragment customChecksList on Graph {\n  customCheckWebhooks {\n    id\n    ...customChecksListItem\n  }\n}\n\nfragment customChecksListItem on CustomCheckWebhook {\n  id\n  url\n  headers {\n    name\n    value\n  }\n  createdAt\n}\n"
  }
};
})();

(node as any).hash = "935d1b8a3f4387032f6713fd3348ace2";

export default node;
