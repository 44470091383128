import { createFileRoute, redirect } from '@tanstack/react-router'
import { authClient } from '#lib/auth/auth-client'
import { redirectToAuth } from '#lib/auth/redirect-to-auth'

export const Route = createFileRoute('/sign-up')({
  staticData: { title: 'Sign up - Grafbase' },
  beforeLoad: async () => {
    document.title = 'Sign up - Grafbase'

    const isSignedIn = await authClient.isSignedIn()

    if (!isSignedIn) {
      await redirectToAuth({
        signInType: 'sign-up',
      })
    }
  },
  loader() {
    throw redirect({ to: '/' })
  },
})
