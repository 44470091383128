/**
 * @generated SignedSource<<c34b7ab75a3bc6afb4afcec9a96981fb>>
 * @relayHash 4261b1610878374339fc223098c14db1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d6b5d7c18822a065e0c3804ab6793ee97ac671e5335f7c063e2bc0edb146b388

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personalAccountLayoutQuery$variables = Record<PropertyKey, never>;
export type personalAccountLayoutQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"sideNavFragment">;
  };
};
export type personalAccountLayoutQuery = {
  response: personalAccountLayoutQuery$data;
  variables: personalAccountLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "personalAccountLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "sideNavFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "personalAccountLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4261b1610878374339fc223098c14db1",
    "id": "d6b5d7c18822a065e0c3804ab6793ee97ac671e5335f7c063e2bc0edb146b388",
    "metadata": {},
    "name": "personalAccountLayoutQuery",
    "operationKind": "query",
    "text": "query personalAccountLayoutQuery {\n  viewer {\n    ...sideNavFragment\n    id\n  }\n}\n\nfragment helpModalFragment on User {\n  organizations {\n    nodes {\n      name\n      slug\n      id\n    }\n  }\n}\n\nfragment profileMenuFragment on User {\n  name\n  email\n  avatarUrl\n}\n\nfragment sideNavBottomLinksFragment on User {\n  ...helpModalFragment\n  ...profileMenuFragment\n}\n\nfragment sideNavFragment on User {\n  ...sideNavBottomLinksFragment\n}\n"
  }
};
})();

(node as any).hash = "57596986aefe78f37a3f48c876b4fc31";

export default node;
