/**
 * @generated SignedSource<<00bfd4652c27e302f1bbaef7fdd07f7a>>
 * @relayHash e5561b66ead7d0a87701747063e2d46e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8d390e6de3f2bd251a22dacbb31d49c0f27e26b847bba5da7bbe63e9a1d0e9d5

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type changelogVersionPageQuery$variables = {
  version: string;
};
export type changelogVersionPageQuery$data = {
  readonly schemaVersion: {
    readonly " $fragmentSpreads": FragmentRefs<"changelogVersionFragment">;
  } | null | undefined;
};
export type changelogVersionPageQuery = {
  response: changelogVersionPageQuery$data;
  variables: changelogVersionPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "version"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "changelogVersionPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaVersion",
        "kind": "LinkedField",
        "name": "schemaVersion",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "changelogVersionFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "changelogVersionPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaVersion",
        "kind": "LinkedField",
        "name": "schemaVersion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Subgraph",
            "kind": "LinkedField",
            "name": "subgraph",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaVersionDelta",
            "kind": "LinkedField",
            "name": "delta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "removed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "modified",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaVersionChangeConnection",
            "kind": "LinkedField",
            "name": "changes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchemaVersionChange",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "path",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5561b66ead7d0a87701747063e2d46e",
    "id": "8d390e6de3f2bd251a22dacbb31d49c0f27e26b847bba5da7bbe63e9a1d0e9d5",
    "metadata": {},
    "name": "changelogVersionPageQuery",
    "operationKind": "query",
    "text": "query changelogVersionPageQuery(\n  $version: ID!\n) {\n  schemaVersion(id: $version) {\n    ...changelogVersionFragment\n    id\n  }\n}\n\nfragment changelogVersionFragment on SchemaVersion {\n  id\n  message\n  createdAt\n  subgraph {\n    name\n  }\n  delta {\n    added\n    removed\n    modified\n  }\n  changes {\n    nodes {\n      type\n      path\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "56b5817b11e5bd4f748e86841fb6f9ef";

export default node;
