/** Performs a full page browser redirect to the auth page
 * @param redirectBackTo - The URL to redirect back to after authentication
 */
export async function redirectToAuth(options?: {
  redirectBackTo?: string
  signInType?: 'sign-in' | 'sign-up'
}) {
  const authUrl = new URL('/_auth/authenticate', window.location.origin)

  if (options?.redirectBackTo)
    authUrl.searchParams.set('redirectUrl', options.redirectBackTo)
  if (options?.signInType)
    authUrl.searchParams.set('signInType', options.signInType)

  window.location.href = authUrl.toString()

  // Block any async flow until browser navigation is complete
  await new Promise(() => {})
}
