import { createFileRoute } from '@tanstack/react-router'
import { object, optional, parse, string } from 'valibot'
import node, {
  type pathfinderPageQuery,
} from '#gen/relay/pathfinderPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

const SearchParamsSchema = object({
  initializeOperation: optional(string()),
})

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/pathfinder',
)({
  staticData: { title: '[graph] - Explorer' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  errorComponent: ErrorHandler,
  loader({ context, params }) {
    return context.preload<pathfinderPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/pathfinder-page'),
    'PathfinderPage',
  ),
})
