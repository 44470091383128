import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/',
)({
  staticData: { title: '[graph] - Settings' },
  beforeLoad: () => {
    throw redirect({
      from: '/$account/$graph/branches/$branch/settings',
      to: '/$account/$graph/branches/$branch/settings/graph',
    })
  },
})
