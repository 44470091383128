let nonce = null as string | null

export function getNonce() {
  if (nonce) {
    return nonce
  }

  nonce =
    document
      .querySelector('meta[property="csp-nonce"]')
      ?.getAttribute('content') || ''

  return nonce
}
