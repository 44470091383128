// @ts-nocheck

import { getNonce } from './get-nonce'

let gtmLoaded = false

export function loadGTM(containerId: string) {
  if (gtmLoaded) return

  const nonce = getNonce()

  const script = document.createElement('script')
  script.id = 'google-tag-manager'
  script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.nonce='${nonce}';j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${containerId}');
          `
  script.async = true
  script.nonce = nonce
  document.body.appendChild(script)
  gtmLoaded = true
}

export function triggerSignedUpEvent() {
  if (!gtmLoaded) {
    return
  }

  window.dataLayer.push({
    event: 'signed-up',
  })
}
