import { createFileRoute } from '@tanstack/react-router'
import node, {
  type customChecksPageQuery,
} from '#gen/relay/customChecksPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/custom-checks',
)({
  staticData: { title: '[graph] - Custom Checks' },
  loader({ context, params }) {
    return context.preload<customChecksPageQuery>(node, {
      ...params,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/checks/custom-checks-page'),
    'CustomChecksPage',
  ),
})
