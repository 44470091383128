/**
 * @generated SignedSource<<6ebcc79ea8d07f4f0205ab51be041455>>
 * @relayHash 3aaf7ebb5d60fa6562ba55bd2ac5004d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c0290b5336c4e5ed7886d675eb7cbc7168c01d4b07ed19fa03a784d3a2d53830

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type proposalPageEditorQuery$variables = {
  proposalId: string;
};
export type proposalPageEditorQuery$data = {
  readonly schemaProposal: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"editorContextProviderFragment" | "editorProposalFragment">;
  };
};
export type proposalPageEditorQuery = {
  response: proposalPageEditorQuery$data;
  variables: proposalPageEditorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "proposalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalPageEditorQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "SchemaProposal",
          "kind": "LinkedField",
          "name": "schemaProposal",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editorProposalFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editorContextProviderFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "proposalPageEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaProposal",
        "kind": "LinkedField",
        "name": "schemaProposal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaProposalSubgraph",
            "kind": "LinkedField",
            "name": "subgraphs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sdl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3aaf7ebb5d60fa6562ba55bd2ac5004d",
    "id": "c0290b5336c4e5ed7886d675eb7cbc7168c01d4b07ed19fa03a784d3a2d53830",
    "metadata": {},
    "name": "proposalPageEditorQuery",
    "operationKind": "query",
    "text": "query proposalPageEditorQuery(\n  $proposalId: ID!\n) {\n  schemaProposal(id: $proposalId) {\n    id\n    ...editorProposalFragment\n    ...editorContextProviderFragment\n  }\n}\n\nfragment editorContextProviderFragment on SchemaProposal {\n  subgraphs {\n    name\n    sdl\n    status\n  }\n}\n\nfragment editorProposalFragment on SchemaProposal {\n  id\n  title\n  description\n}\n"
  }
};
})();

(node as any).hash = "9ef9214727d46c7c7c8e44c57c8ace31";

export default node;
