import { getNonce } from './get-nonce'

let hasLoaded = false

export function loadOpenPanel(clientId: string) {
  if (hasLoaded) return

  const nonce = getNonce()

  const script = document.createElement('script')
  script.id = 'open-panel'
  script.innerHTML = `
        window.op = window.op||function(...args){(window.op.q=window.op.q||[]).push(args);};
        window.op('init', {
        clientId: '${clientId}',
        trackScreenViews: true,
        trackOutgoingLinks: true,
        trackAttributes: true,
        });
    `
  script.nonce = nonce
  script.async = true
  document.body.appendChild(script)
  const openPanelScript = document.createElement('script')
  openPanelScript.src = 'https://openpanel.dev/op1.js'
  openPanelScript.defer = true
  openPanelScript.async = true
  openPanelScript.nonce = nonce
  document.body.appendChild(openPanelScript)

  hasLoaded = true
}

export function openPanelIdentify(user: { email: string; name?: string }) {
  if (!hasLoaded) {
    return
  }

  const op = (window as any).op

  op?.('identify', {
    profileId: user.email,
    email: user.email,
    firstName: user.name,
  })
}
