import { createFileRoute } from '@tanstack/react-router'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings',
)({
  staticData: { title: '[graph] - Settings' },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/graph-layout/graph-settings-layout'),
    'GraphSettingsLayout',
  ),
})
