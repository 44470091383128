/**
 * @generated SignedSource<<b82e4bf4951f53b48618a36a6543a8a4>>
 * @relayHash 93a2392267bffc8aa9dfb973684673b1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a4b12054a2ae4dd7e12bf504012ac7d02939793565667c92f461bf6feb934d2c

import { ConcreteRequest } from 'relay-runtime';
export type SchemaCheckErrorSeverity = "ERROR" | "WARNING";
export type SchemaCheckStep = "COMPOSITION" | "CUSTOM" | "LINT" | "OPERATION" | "PROPOSAL" | "VALIDATION";
export type checkDetailsPageQuery$variables = {
  id: string;
};
export type checkDetailsPageQuery$data = {
  readonly schemaCheck: {
    readonly createdAt: string;
    readonly diagnostics: ReadonlyArray<{
      readonly message: string;
      readonly severity: SchemaCheckErrorSeverity;
      readonly step: SchemaCheckStep;
    }>;
    readonly errorCount: number;
    readonly gitCommit: {
      readonly branch: string;
    } | null | undefined;
    readonly subgraphName: string | null | undefined;
  } | null | undefined;
};
export type checkDetailsPageQuery = {
  response: checkDetailsPageQuery$data;
  variables: checkDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subgraphName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errorCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SchemaCheckGitCommit",
  "kind": "LinkedField",
  "name": "gitCommit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branch",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SchemaCheckDiagnostic",
  "kind": "LinkedField",
  "name": "diagnostics",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "severity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "step",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaCheck",
        "kind": "LinkedField",
        "name": "schemaCheck",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkDetailsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchemaCheck",
        "kind": "LinkedField",
        "name": "schemaCheck",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93a2392267bffc8aa9dfb973684673b1",
    "id": "a4b12054a2ae4dd7e12bf504012ac7d02939793565667c92f461bf6feb934d2c",
    "metadata": {},
    "name": "checkDetailsPageQuery",
    "operationKind": "query",
    "text": "query checkDetailsPageQuery(\n  $id: ID!\n) {\n  schemaCheck(id: $id) {\n    createdAt\n    subgraphName\n    errorCount\n    gitCommit {\n      branch\n    }\n    diagnostics {\n      message\n      severity\n      step\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b13b1d77904f39c0b22341d57bcbcbc4";

export default node;
