import { createContext, useContext } from 'react'

export type ResolvedTheme = 'light' | 'dark'
export type Theme = ResolvedTheme | 'system'

export const ThemeContext = createContext<
  [{ theme: Theme; resolvedTheme: 'light' | 'dark' }, (value: Theme) => void]
>([{ theme: 'system', resolvedTheme: 'dark' }, () => {}])

export function useTheme() {
  return useContext(ThemeContext)
}
