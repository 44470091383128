/**
 * @generated SignedSource<<7d8fa5dd6abe8fa075a031ccbf1cbb31>>
 * @relayHash 29dbc35c6d4243d7730aa4ed7c036aaa
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b379192cc4c147a2d3b7d348e9f88091808b888c815a84d89f42b2f3e1378895

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dashboardLayoutQuery$variables = Record<PropertyKey, never>;
export type dashboardLayoutQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"dashboardLayoutIdentifyUserFragment" | "dynamicPageTitle" | "providerFeatureFlagsFragment" | "selectsDataWrapperProviderAccountsFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"accountPermissionsProviderFragment" | "headerFragment">;
};
export type dashboardLayoutQuery = {
  response: dashboardLayoutQuery$data;
  variables: dashboardLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardLayoutQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "headerFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accountPermissionsProviderFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "dashboardLayoutIdentifyUserFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "selectsDataWrapperProviderAccountsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "providerFeatureFlagsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "dynamicPageTitle"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trialExpiresAt",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29dbc35c6d4243d7730aa4ed7c036aaa",
    "id": "b379192cc4c147a2d3b7d348e9f88091808b888c815a84d89f42b2f3e1378895",
    "metadata": {},
    "name": "dashboardLayoutQuery",
    "operationKind": "query",
    "text": "query dashboardLayoutQuery {\n  ...headerFragment\n  ...accountPermissionsProviderFragment\n  viewer {\n    ...dashboardLayoutIdentifyUserFragment\n    ...selectsDataWrapperProviderAccountsFragment\n    ...providerFeatureFlagsFragment\n    ...dynamicPageTitle\n    id\n  }\n}\n\nfragment accountPermissionsProviderFragment on Query {\n  viewer {\n    organizationMemberships {\n      role\n      account {\n        __typename\n        id\n        slug\n        plan\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment accountSelectFragment on User {\n  name\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment dashboardLayoutIdentifyUserFragment on User {\n  id\n  name\n  email\n  createdAt\n  avatarUrl\n  organizations {\n    nodes {\n      id\n      slug\n      name\n      plan\n    }\n  }\n}\n\nfragment dynamicPageTitle on User {\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment headerFragment on Query {\n  viewer {\n    ...headerRemainingTrialTimeMessageFragment\n    id\n  }\n}\n\nfragment headerRemainingTrialTimeMessageFragment on User {\n  organizations {\n    nodes {\n      plan\n      slug\n      trialExpiresAt\n      id\n    }\n  }\n}\n\nfragment providerFeatureFlagsFragment on User {\n  id\n  email\n  name\n  organizations {\n    nodes {\n      id\n      slug\n      plan\n    }\n  }\n}\n\nfragment selectsDataWrapperProviderAccountsFragment on User {\n  ...accountSelectFragment\n}\n"
  }
};
})();

(node as any).hash = "54a5efe592be22b8e27cafcb1345c833";

export default node;
