import { createFileRoute } from '@tanstack/react-router'
import node, {
  type operationChecksPageQuery,
} from '#gen/relay/operationChecksPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/operation-checks',
)({
  staticData: { title: '[graph] - Operation Checks' },
  loader({ context, params }) {
    return context.preload<operationChecksPageQuery>(node, {
      ...params,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/checks/operation-checks-page'),
    'ChecksConfigurationPage',
  ),
})
