import { createFileRoute } from '@tanstack/react-router'
import node, { type checksPageQuery } from '#gen/relay/checksPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

const PAGE_SIZE = 30

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/checks/',
)({
  staticData: { title: '[graph] - Checks' },
  loader({ context, params }) {
    return context.preload<checksPageQuery>(node, {
      ...params,
      first: PAGE_SIZE,
      after: null,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/checks/checks-page'),
    'ChecksPage',
  ),
})
