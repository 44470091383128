import { createFileRoute } from '@tanstack/react-router'
import node, {
  type personalAccountLayoutQuery,
} from '#gen/relay/personalAccountLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'

export const Route = createFileRoute('/_dashboard/_personal')({
  staticData: { title: '' },
  async loader({ context, params }) {
    return context.preload<personalAccountLayoutQuery>(node)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/personal/personal-account-layout'),
    'PersonalAccountLayout',
  ),
})
