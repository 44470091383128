import { getNonce } from './get-nonce'

let syftLoaded = false

export function loadSyft(sourceId: string) {
  if (syftLoaded) return

  const nonce = getNonce()

  const script = document.createElement('script')
  script.id = 'syft-script'
  script.innerHTML = `!function(t){if(window.syftc=t,window.syft)return;window.syft=[],["identify","track","page","signup"].forEach(function(t){window.syft[t]=function(){var s=[].slice.call(arguments);s.unshift(t),window.syft.push(s)}});var s=document.createElement("script");s.nonce='${nonce}';s.async=!0,s.setAttribute("src","https://cdn.syftdata.com/syftnext/syft.umd.js"),(document.body||document.head).appendChild(s)}({sourceId:"${sourceId}"});`
  script.async = true
  script.nonce = nonce
  document.body.appendChild(script)
  syftLoaded = true
}

export function syftIdentify(
  user: { email: string; name?: string },
  isNewSignup: boolean,
) {
  if (!syftLoaded) {
    return
  }

  const syft = (window as any).syft

  syft?.identify(user.email, {
    source: isNewSignup ? 'signup' : 'login',
    firstName: user.name,
  })
}
