import { createFileRoute, redirect } from '@tanstack/react-router'
import { authClient } from '#lib/auth/auth-client'
import { redirectToAuth } from '#lib/auth/redirect-to-auth'

export const Route = createFileRoute('/sign-in')({
  staticData: { title: 'Sign in - Grafbase' },
  beforeLoad: async () => {
    document.title = 'Sign in - Grafbase'

    const isSignedIn = await authClient.isSignedIn()

    if (!isSignedIn) {
      await redirectToAuth({
        signInType: 'sign-in',
      })
    }
  },
  loader() {
    throw redirect({ to: '/' })
  },
})
