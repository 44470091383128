/**
 * @generated SignedSource<<77ac58f47a61000953bc4f28af0d2139>>
 * @relayHash bc0e21fac39c485740c40c5d472f8895
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae61cea0de085b5c65ec1bc89ddb55b92e2f3426bbea99dc3f7a5a4ac4630b21

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrafbasePlan = "ENTERPRISE" | "GROWTH" | "TRIAL";
export type accountLayoutQuery$variables = Record<PropertyKey, never>;
export type accountLayoutQuery$data = {
  readonly viewer: {
    readonly organizations: {
      readonly nodes: ReadonlyArray<{
        readonly plan: GrafbasePlan;
        readonly slug: string;
        readonly trialExpiresAt: string | null | undefined;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"selectsDataWrapperProviderAccountsFragment" | "sideNavFragment">;
  };
};
export type accountLayoutQuery = {
  response: accountLayoutQuery$data;
  variables: accountLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialExpiresAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationConnection",
              "kind": "LinkedField",
              "name": "organizations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "sideNavFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "selectsDataWrapperProviderAccountsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc0e21fac39c485740c40c5d472f8895",
    "id": "ae61cea0de085b5c65ec1bc89ddb55b92e2f3426bbea99dc3f7a5a4ac4630b21",
    "metadata": {},
    "name": "accountLayoutQuery",
    "operationKind": "query",
    "text": "query accountLayoutQuery {\n  viewer {\n    organizations {\n      nodes {\n        slug\n        plan\n        trialExpiresAt\n        id\n      }\n    }\n    ...sideNavFragment\n    ...selectsDataWrapperProviderAccountsFragment\n    id\n  }\n}\n\nfragment accountSelectFragment on User {\n  name\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment helpModalFragment on User {\n  organizations {\n    nodes {\n      name\n      slug\n      id\n    }\n  }\n}\n\nfragment profileMenuFragment on User {\n  name\n  email\n  avatarUrl\n}\n\nfragment selectsDataWrapperProviderAccountsFragment on User {\n  ...accountSelectFragment\n}\n\nfragment sideNavBottomLinksFragment on User {\n  ...helpModalFragment\n  ...profileMenuFragment\n}\n\nfragment sideNavFragment on User {\n  ...sideNavBottomLinksFragment\n}\n"
  }
};
})();

(node as any).hash = "cb3a520e833c0796ec3b11fbbbfa892f";

export default node;
